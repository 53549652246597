import React from "react"

const Zoom = () => {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18.314"
    height="18.314"
    viewBox="0 0 18.314 18.314"
  >
    <defs>
      <style dangerouslySetInnerHTML={{ __html: ".a{fill:#fff;}" }} />
    </defs>
    <path
      className="a"
      d="M10.114,2A8.114,8.114,0,1,1,2,10.114,8.117,8.117,0,0,1,10.114,2Zm0,14.425A6.311,6.311,0,1,0,3.8,10.114,6.31,6.31,0,0,0,10.114,16.425Zm7.65.064,2.55,2.55-1.276,1.276-2.55-2.55Z"
      transform="translate(-2 -2)"
    />
  </svg>
  )
}

export default Zoom
